

















































































import { AutenticadorService } from '@/core/services/shared/AutenticadorService';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';

@Component
export default class Login extends Vue {
  @Prop() private value!: boolean;

  logo: string = '';
  hidePassword: boolean = true;
  loading: boolean = false;
  loadingReset: boolean = false;
  showPassword: boolean = false;
  acesso: {login: string, senha: string} = {
    login: '',
    senha: ''
  };
  fieldRules: any[] = [
    (v: any) => !!v || 'Campo obrigatório',
  ]
  service = new AutenticadorService();
  resetarSenha: boolean = false;
  token: string = "";

  @Watch('resetarSenha')
  ResetarForm(){
    this.acesso.login = "";
    this.acesso.senha = "";
  }

  mounted() {
    if(this.$route.params.token){
      this.token = this.$route.params.token;
      this.resetarSenha
    }  
  }

  Autenticar(){
    this.loading = true;
    localStorage.clear();

    this.service.AutenticarUsuario(this.acesso).then(
      res => {
        localStorage.setItem('sessionApp', JSON.stringify(res.data));                  
        this.$swal('Aviso', res.data.message, res.status == 200 ? 'success' : 'warning');
        this.$router.push({ name: 'Home' });
      }, 
      err => {
        localStorage.clear();
        this.$swal('Aviso', err.response.data, err.response.status === 400 ? 'warning' : 'error')
      }
    ).finally(() => this.loading = false);
  }

  EnviarEmail(){
    this.loadingReset = true;
    this.service.ResetarSenha(this.acesso.login, this.token, this.acesso.senha).then(
      res =>{
        this.$swal('Aviso', res.data, res.status == 200 ? 'success' : 'warning');
        this.$router.replace({name:'Login'});
        setTimeout(() => {
          location.reload();
        }, 2500);
      },
      err => this.$swal('Aviso', err.response.data, err.response.status === 400 ? 'warning' : 'error')
    ).finally(() => this.loadingReset = false)
  }
}
